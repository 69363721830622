import axios from 'axios';
import { SWRConfig } from 'swr'
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app'
import Head from "next/head";

import '../styles/main.css';

function MyApp({ Component, pageProps }: AppProps) {

    const loadMobileMenuScript = () => {
        const script = document.createElement('script');

        script.src = '/resources/js/main.js';
        script.async = false;

        document.body.appendChild(script);
    };

    const loadExtendedScripts = () => {
        const script = document.createElement('script');

        script.src = '/resources/js/main.ext.js';
        script.async = false;

        document.body.appendChild(script);
    };

    useEffect(() => {
        loadMobileMenuScript();
        loadExtendedScripts();
    }, []);

    return (
        <SWRConfig value={{ fetcher: (url: string) => axios.get(url).then(res => res.data) }}>
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
            </Head>
            <Component {...pageProps} />
        </SWRConfig>
    );
}

export default MyApp
